<template>
     <section class="dictionary-management padding_10_15">
          <div class="top-bar bg-white">
               <el-button
                       type="primary"
                       icon="el-icon-plus"
                       @click="handleAddEditDictionary('add', {})"
                       class="addDictionary" size="small">
                    新增字典
               </el-button>
               <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索"
                         style="margin-right: 9px; width: 250px" size="small"/>
               <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
               <!-- getDictionaryTableData -->
               <el-button @click="getDictionaryTableData()" size="small">重置</el-button>
               <!-- <el-button @click="formSearch={keyword:''};handleSearch()" size="small">重置</el-button> -->
          </div>
          <r-e-table
                  class="bg-white"
                  ref="dictionaryTableRef"
                  rowKeyId="code"
                  :data="tableData"
                  :default-sort="{prop: 'code', order: 'ascending'}"
                  :columns="tableColumn"
                  :query="formSearch"
                  :show-pagination="false"
                  :height="840"
          >
               <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                         <div class="table-tools">
                               <span
                                       class="table-btn"
                                       @click="handleAddEditDictionary('edit', row)"
                               >
                                    编辑
                               </span>
                              <span
                                      v-if="row.flag"
                                      class="table-btn"
                                      @click="handleAddEditDictionary('add',{uuidParent:row.uuid})"
                              >
                                   新增
                              </span>
                         </div>
                    </template>
               </el-table-column>
               <template slot="empty">
                    <el-empty></el-empty>
               </template>
          </r-e-table>
          <!--   添加、修改字典弹框       -->
          <layer-add-edit-dictionary class="layer-add-edit-dictionary" ref="dictionaryRef" />
     </section>
</template>

<script>
    import * as api from "@/api/dictionary";
    import axios from "axios";
    import { tableColumn } from "@/views/system-management/dictionary-management/data";
    import { layerAddEditDictionary } from "@/views/system-management/dictionary-management/components";
    import _ from "lodash";

    export default {
        name: "dictionary-management",
        data() {
            return {
                tableColumn,
                tableData: [],
                tableData2: [],
                formSearch: {
                    keyword:''
                }
            };
        },
        components: { layerAddEditDictionary },
        created() {
            this.getDictionaryTableData();
        },
        methods: {
            getDictionaryTableData() {
                this.formSearch = {}
                // 获取所有父类
                api.getParents().then(response => {
                    console.log('response',response);
                    const { list = [] } = response;
                    this.$refs.dictionaryTableRef.pNumber = 1;
                    this.tableData = [];
                    this.tableData2 = [];
                    list.forEach(v => {
                        v.flag = true;
                        api.getByParent(v.uuid).then((res) => {
                            v.children = res.list;
                            this.tableData.push(v);
                            this.tableData2.push(_.cloneDeep(v));
                        });
                    });
                });
            },
            afterDataReq(e) {
                e.forEach(v => {
                    v.flag = true;
                    api.getByParent(v.uuid).then(res => { // 根据父类uuid获取所有子类
                        v.children = res.list;
                        this.tableData.push(v);
                        this.tableData2.push(_.cloneDeep(v));
                    });
                });
            },
            handleSearch(params) {
                console.log('params',params);
                console.log('this.formSearch.keyword',this.formSearch.keyword);
                // 2024/02/04 张晓瑜新增查询只查父级
                api.getParents({ keyword: this.formSearch.keyword}).then(response => {
                    const { list = [] } = response;
                    this.tableData = list
                });
                // 根据uuid codeName查询所有子类
                let t = _.cloneDeep(this.tableData2);
                t.forEach(v => {
                    v.children = this.tableData2.find(v2 => v2.uuid === v.uuid).children.filter(v => v.codeName.toLowerCase().indexOf(this.formSearch.keyword) > -1);
                });
                let data = t.filter(v => v.children.length > 0);
                this.tableData = data;
                this.$refs.dictionaryTableRef.pNumber = 1;
                this.$refs.dictionaryTableRef.total = data.length;
            },
            handleAddEditDictionary(addEdit, row) {
                // 新增、编辑打开弹窗
                this.$refs.dictionaryRef.layerAddEditDictionaryInit(addEdit, row);
            }
        }
    };
</script>

<style lang="scss" scoped>
     .dictionary-management {

          .top-bar {
               display: flex;
               margin: VH(10px) 0 VH(25px);
               padding: VH(15px) VW(15px);
               justify-content: center;

               .addDictionary {
                    position: absolute;
                    left: VW(40px);
               }
          }
     }
</style>
