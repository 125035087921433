<template>
    <section class="message-reminder padding_10_15">
        <div class="top-bar bg-white">
            <h2 style="margin: 0 5px">消息中心</h2>
            <el-button icon="el-icon-refresh" size="small" style="margin: 0 5px" @click="handleReset"/>
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 5px; width: 250px" size="small"/>
            <el-button type="primary" size="small" style="margin: 0 5px" @click="handleSearch">查询</el-button>
        </div>
        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getMsgs" :columns="tableColumn" :query="formSearch"
                   :height="830">
            <el-table-column prop="title" label="消息" :show-overflow-tooltip="true">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="goTo(row)">{{row.title}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="title" label="操作">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="Read(row)">已读</span>
                    </div>
                </template>
            </el-table-column>
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import {tableColumn} from "./data";
    import {getMsgs, dismiss} from "@/api/log";
    import {outstandingCount, alertsCount} from "@/api/home";
    import {MessageSuccess} from "@custom/message";
    export default {
        name: "message-reminder",
        data() {
            return {
                tableColumn,
                tableData: [],
                formSearch: {
                    keyword:""
                },
                radio1: 1,
                allCount: 0,
                zfCount: 0,
                htCount: 0,
                czfCount: 0,
                bzCount: 0,
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
            };
        },
        components: {},
        async mounted() {
            let zfRes = await outstandingCount();
            this.allCount = zfRes.info;
            let gzfRes = await alertsCount();
            const {房租, 合同, 承租方, 备注} = gzfRes.info;
            this.zfCount = 房租;
            this.htCount = 合同;
            this.czfCount = 承租方;
            this.bzCount = 备注;
        },
        methods: {
            getMsgs(params) {
                return getMsgs(params);
            },
            handleSearch() {
                this.$refs["orderTableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                this.radio1 = 1;
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            goTo(data) {
                const {url, type, title} = data;
                if (url) {
                    let urlList = url !== "" ? url.split("+-+").filter(item => item !== "") : [];
                    let paramsData = {};
                    let propList = urlList[1].split(";").filter(item => item !== "") !== 0 ? urlList[1].split(";").filter(item => item !== "") : [];
                    if (propList.length !== 0) {
                        for (let item of propList) {
                            let propArr = item.split(":").filter(item => item !== "") !== 0 ? item.split(":").filter(item => item !== "") : [];
                            if (propArr.length !== 0) paramsData[propArr[0]] = propArr[1];
                        }
                    }
                    if(type==="租房合同") this.$router.push({path: urlList[0], query: {data: paramsData}});
                }
                if (type === "账单") {
                    let k = (title.split("账单提醒：房源").filter(v => v !== "")[1].split("室"))[0] + "室";
                    this.$router.push({name: "payment-record", params: {data: {keyWordCode: 3, keyWord: k}}});
                }
                if (type === "智能管控") {
                    let k = (title.split("读取失败：").filter(v => v !== "")[1].split("室"))[0] + "室";
                    this.$router.push({name: "door-management", params: {data: {apartmerntName: k}}});
                }
            },
            Read(data){
                const {uuid} = data;
                let that = this;
                this.$confirm('此操作将会确认已读该条消息, 是否继续', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    dismiss(uuid).then(res => {
                        loading.close();
                        MessageSuccess('操作成功!');
                        this.handleSearch();
                    }).catch(()=>loading.close());
                }).catch(() => {});
            }
        }
    }
</script>
<style lang="scss" scoped>
    .message-reminder {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>